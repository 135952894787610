import { toHTML } from "@portabletext/to-html";
import type {
  Product,
  SubscriptionOption,
} from "@bluebottlecoffee/design-system/components/lib/types";
import { ConversionProps } from "@bluebottlecoffee/design-system/components";
import { DereferencedShopCard } from "../sanity/shop-card-queries";
import { toImageResponsiveProps } from "./image";
import { Product as ProductSchema } from "../sanity-schema";
import { SanityKeyed } from "../sanity-keyed";
import { toVariantProps } from "./variant";
import { DereferencedOptionType } from "../sanity/product-queries";
import { DereferencedVariant } from "../sanity/variant-queries";

export type ConversionSchema = Omit<
  ProductSchema,
  | "crossSellProducts"
  | "optionTypes"
  | "subscription"
  | "subscriptionOptions"
  | "variants"
  | "shopcard"
  | "bundle"
> & {
  crossSellProducts?: ProductSchema[];
  optionTypes: DereferencedOptionType[];
  primaryShopCollectionName?: string;
  subscription: Product["subscription"];
  subscriptionOptions: SanityKeyed<SubscriptionOption>[];
  variants: DereferencedVariant[];
  shopCard?: DereferencedShopCard;
  bundle?: {
    bundledProduct: ConversionSchema;
    productBundle: ConversionSchema;
  };
};

export type ProductWithShortDescription = Product & {
  shortDescription: string;
};

export function toProductProps(
  {
    slug,
    name,
    subscription,
    subscriptionOptions,
    variants,
    description,
    images,
    subscriptionType,
    isGiftCard,
    soldOutCtas,
    optionTypes,
    quickSubscribeCard,
  }: ConversionSchema,
  lang: string,
): ProductWithShortDescription {
  const shortDescription = quickSubscribeCard?.product?.description
    ? toHTML(quickSubscribeCard.product.description[lang])
    : toHTML(description[lang]);
  return {
    description: toHTML(description[lang]),
    images: images.map((i) => toImageResponsiveProps(i, lang)),
    name: name[lang],
    shortDescription,
    slug: slug.current,
    subscription,
    subscriptionOptions:
      subscriptionOptions?.map((subscriptionOption) => {
        // subscriptionOptions are Sanity objects, which do not have _id's :(
        // so mapping _key to component expected type _id
        // rather than refactoring the world rn
        const { _key, quantityLabel, ...rest } = subscriptionOption;

        // quantityLabel.label comes through collection and shop pages from Algolia as an object
        // whereas on the PDP, it is a string since it is localized in the Sanity query
        const label =
          quantityLabel.label && typeof quantityLabel.label === "object"
            ? quantityLabel.label![lang]
            : quantityLabel.label;

        return {
          ...rest,
          quantityLabel: { ...quantityLabel, label },
          _id: _key,
        };
      }) || null,
    subscriptionType: subscriptionType || null,
    variants: variants
      .filter((v) => v.forSale)
      .map((v) => toVariantProps(v, lang)),
    isGiftCard: isGiftCard || null,
    soldOutCtas: soldOutCtas
      ? soldOutCtas.map((soldOutCta) => {
          const { text, internal, isAnchorLink, external, blank } = soldOutCta;
          return {
            blank,
            isAnchorLink,
            text: text[lang],
            url: internal || external,
          };
        })
      : null,
    optionTypes: optionTypes.map((optionType) => ({
      presentation: optionType.presentation ?? null,
    })),
  };
}

export function toConversionProps(
  data: ConversionSchema,
  lang: string,
): Omit<
  ConversionProps,
  | "addToCart"
  | "addToCartProps"
  | "copy"
  | "createStockRequest"
  | "subscribeProduct"
  | "useVariantAvailability"
  | "modifyGiftCards"
  | "emailIsValid"
  | "giftCardFormCopy"
  | "readMoreTriggerAriaLabel"
  | "readMoreTriggerLabel"
  | "shippingCopy"
  | "useVariantPrice"
> {
  return {
    product: toProductProps(data, lang),
  };
}
